import React, { useState, useRef, useEffect, useCallback, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/sharp-light-svg-icons';
import { faCircleXmark } from '@fortawesome/sharp-thin-svg-icons';
import { faCircleArrowRight } from '@fortawesome/sharp-thin-svg-icons';
import Preloader from './Preloader';
import './App.css';

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

function App() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [firstOpen, setFirstOpen] = useState(true);
    const [activeSection, setActiveSection] = useState('');
    const [scrollingManually, setScrollingManually] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const [isAnimating, setIsAnimating] = useState(false);
    const [isPortrait, setIsPortrait] = useState(false);


    const menuRef = useRef(null);
    const menuItemsRef = useRef([]);
    const sectionRefs = useRef([]);
    const horizontalSectionsContainerRef = useRef(null);
    const appRef = useRef(null);
    const isDownRef = useRef(false);
    const startXRef = useRef(0);
    const scrollLeftRef = useRef(0);

    // Detect if the device is an iPad (exclude iPhone)
    const isIpad = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Detect iPad using the userAgent and screen properties
        const isIPadUserAgent = /iPad/.test(userAgent);
        const isIPadDevice = (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1); // iPadOS in desktop mode
        const isIPhone = /iPhone/.test(userAgent) || /iPod/.test(userAgent);

        // Only consider iPad, excluding iPhones or iPods
        return (isIPadUserAgent || isIPadDevice) && !isIPhone;
    };

    // Function to update portrait orientation for iPads
    const updateOrientation = () => {
        if (isIpad()) {
            setIsPortrait(window.matchMedia("(orientation: portrait)").matches);
        } else {
            setIsPortrait(false);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", updateOrientation);
        updateOrientation(); // Initial check when component mounts
        return () => {
            window.removeEventListener("resize", updateOrientation);
        };
    }, []);

    // Function for dynamic custom 100% height

    useEffect(() => {
        const setFullHeight = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };

        // Run on load
        setFullHeight();

        // Run on resize to adjust when the viewport height changes
        window.addEventListener('resize', setFullHeight);

        // Cleanup on unmount
        return () => {
            window.removeEventListener('resize', setFullHeight);
        };
    }, []);

    // Function to update the menu UI
    const updateMenu = useCallback(() => {
        if (menuItemsRef.current.length > 0) {
            menuItemsRef.current.forEach((item) => {
                const activeLine = item.querySelector('.menu-li-active');
                const link = item.querySelector('a');

                if (link && activeLine) {
                    if (item.getAttribute('data-section') === activeSection) {
                        const linkWidth = link.getBoundingClientRect().width || link.offsetWidth;

                        gsap.to(activeLine, {
                            width: `${linkWidth - 20}px`,
                            duration: 0.4,
                            ease: 'power2.out',
                        });
                    } else {
                        gsap.to(activeLine, {
                            width: 0,
                            duration: 0.4,
                            ease: 'power2.out',
                        });
                    }
                }
            });
        }
    }, [activeSection]);

    useEffect(() => {
        if (isMobile) {
            const svgHeading = document.querySelector('.svg-heading');
            const homeButton = document.querySelector('.home-button');

            // Set initial opacity of home button
            gsap.set(homeButton, { opacity: 0 });

            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (!entry.isIntersecting) {
                            // svg-heading is out of view, fade in home-button
                            gsap.to(homeButton, { opacity: 1, duration: 1, ease: 'power2.out' });
                        } else {
                            // svg-heading is in view, fade out home-button
                            gsap.to(homeButton, { opacity: 0, duration: 1, ease: 'power2.out' });
                        }
                    });
                },
                { threshold: 0.1 } // Trigger when 10% of svg-heading is out of view
            );

            if (svgHeading) {
                observer.observe(svgHeading);
            }

            // Clean up observer on unmount
            return () => {
                if (svgHeading) {
                    observer.unobserve(svgHeading);
                }
            };
        }
    }, [isMobile]);


// Function to open the menu
    const openMenu = useCallback(() => {
        if (!menuRef.current || menuItemsRef.current.length === 0) return;

        setIsAnimating(true); // Set animating to true at the start

        // Set all items to hidden before animation
        menuItemsRef.current.forEach(el => {
            if (el) gsap.set(el.querySelector('.menu-item'), { y: '100%' });
        });

        const timeline = gsap.timeline({
            onComplete: () => setIsAnimating(false), // Reset isAnimating when done
        });

        const activeColor = getCSSVariable('--hamburger-active-color');

        if (firstOpen) {
            timeline
                .set(menuItemsRef.current.map(el => el.querySelector('.menu-item')), { y: '100%' })
                .to(menuRef.current, { x: 0, duration: 1, ease: "expo.inOut" }) // Slide in the menu
                .to(menuItemsRef.current.map(el => el.querySelector('.menu-item')), {
                    y: 0,
                    duration: 0.4,
                    ease: "power4.out"
                }, "-=0.7"); // Animate all items at once with slight overlap
            setFirstOpen(false);
        } else {
            timeline
                .set(menuItemsRef.current.map(el => el.querySelector('.menu-item')), { y: '100%' })
                .to(menuRef.current, { x: 0, duration: 1, ease: "expo.inOut" })
                .to(menuItemsRef.current.map(el => el.querySelector('.menu-item')), {
                    y: 0,
                    duration: 0.4,
                    ease: "power4.out"
                }, "-=0.99");
        }

        // Update the colors of the hamburger icon and other elements
        timeline.to([
            '.hamburger-icon span',
            '.vertical-line'
        ], { backgroundColor: activeColor, duration: 0.3, ease: 'power4.out' }, "-=1");

        timeline.to([
            '.home-button',
            '.home-button-icon svg',
        ], { color: activeColor, duration: 0.3, ease: 'power4.out' }, "-=1");

        menuRef.current.style.visibility = 'visible';
    }, [firstOpen]);

// Function to close the menu
    const closeMenu = useCallback(() => {
        if (!menuRef.current || menuItemsRef.current.length === 0) return;

        setIsAnimating(true); // Set animating to true at the start

        const timeline = gsap.timeline({
            onComplete: () => setIsAnimating(false), // Reset isAnimating when done
        });

        const whiteColor = getCSSVariable('--white');
        const vertLine = getCSSVariable('--vert-line');

        // Slide items back down
        timeline.to(menuItemsRef.current.map(el => el.querySelector('.menu-item')), {
            y: '100%',
            duration: 0.6,
            ease: "power4.in"
        });

        // Slide out the menu
        timeline.to(menuRef.current, {
            x: '-100%',
            duration: 1,
            ease: "expo.inOut",
            delay: 0.2
        });

        // Restore original colors
        timeline.to('.hamburger-icon span:nth-child(1)', { backgroundColor: getCSSVariable('--davis-color'), duration: 0.3, ease: 'power4.out' }, "-=0.5");
        timeline.to('.hamburger-icon span:nth-child(2)', { backgroundColor: getCSSVariable('--farm-color'), duration: 0.3, ease: 'power4.out' }, "-=0.5");
        timeline.to('.hamburger-icon span:nth-child(3)', { backgroundColor: getCSSVariable('--rediger-color'), duration: 0.3, ease: 'power4.out' }, "-=0.5");
        timeline.to('.hamburger-icon span:nth-child(4)', { backgroundColor: getCSSVariable('--reunion-color'), duration: 0.3, ease: 'power4.out' }, "-=0.5");
        timeline.to('.home-button', { color: whiteColor, duration: 0.3, ease: 'power4.out' }, "-=0.5");
        timeline.to('.home-button-icon svg', { color: whiteColor, duration: 0.3, ease: 'power4.out' }, "-=0.5");
        timeline.to('.vertical-line', { backgroundColor: vertLine, duration: 0.3, ease: 'power4.out' }, "-=0.5");

        setTimeout(() => {
            const menuElement = document.querySelector('.menu');
            menuElement.classList.remove('show');
            menuRef.current.style.visibility = 'hidden';
        }, 1500); // Hide after animations are complete
    }, []);

// Toggle function for opening/closing the menu
    const toggleMenu = useCallback(() => {
        if (isAnimating) return; // Prevent toggle while animating

        setMenuOpen(prev => {
            const newMenuOpen = !prev;
            const menuElement = document.querySelector('.menu');
            if (newMenuOpen) {
                menuElement.classList.add('show');
                openMenu();
            } else {
                closeMenu();
                setTimeout(() => {
                    menuElement.classList.remove('show');
                }, 1500);
            }
            return newMenuOpen;
        });
    }, [closeMenu, openMenu, isAnimating]);




    // Handle Preloader Complete
    const handlePreloaderComplete = () => {
        // Check if fonts are loaded before setting isLoaded to true
        if (document.fonts) {
            document.fonts.ready.then(() => {
                setIsLoaded(true);
                setLoading(false); // Hide the preloader
            });
        } else {
            setIsLoaded(true);
            setLoading(false); // Fallback if document.fonts is not supported
        }
    };

    // Check and update mobile state
    useEffect(() => {
        // Check if the current device is mobile
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 767);
        };

        checkMobile(); // Initial check
        window.addEventListener('resize', checkMobile); // Re-check on resize

        return () => {
            window.removeEventListener('resize', checkMobile);
        };
    }, []);

    // Update container scroll settings based on `isMobile` state
    // Update container scroll settings based on `isMobile` state
    useEffect(() => {
        const container = horizontalSectionsContainerRef.current;

        if (isMobile) {
            // Mobile: Stack sections vertically and enable vertical scroll
            container.style.overflowX = 'hidden';
            container.style.overflowY = 'auto';
            container.style.display = 'flex';
            container.style.flexDirection = 'column';
        } else {
            // Desktop: Keep horizontal scroll and layout
            container.style.overflowX = 'scroll';
            container.style.overflowY = 'hidden';
            container.style.display = 'flex';
            container.style.flexDirection = 'row';
        }
    }, [isMobile]);


    // Add/Remove event listeners based on `isMobile`
    useEffect(() => {
        const container = horizontalSectionsContainerRef.current;

        if (!isMobile) {
            // Dragging and scroll-related functions for desktop
            const startDragging = (e) => {
                isDownRef.current = true;
                startXRef.current = e.pageX - container.offsetLeft;
                scrollLeftRef.current = container.scrollLeft;
                document.body.classList.add('no-select');
            };

            const stopDragging = () => {
                isDownRef.current = false;
                document.body.classList.remove('no-select');
            };

            const whileDragging = (e) => {
                if (!isDownRef.current) return;
                e.preventDefault();
                const x = e.pageX - container.offsetLeft;
                const walk = (x - startXRef.current) * 3; // Adjust scrolling speed if needed
                gsap.to(container, {
                    scrollLeft: scrollLeftRef.current - walk,
                    duration: 1,
                    ease: "power4.out",
                });
            };

            const handleWheel = (e) => {
                if (e.deltaY !== 0) {
                    e.preventDefault();
                    const currentScroll = container.scrollLeft;
                    gsap.killTweensOf(container);
                    gsap.to(container, {
                        scrollLeft: currentScroll + e.deltaY * 3,
                        duration: 1,
                        ease: "power4.out",
                    });
                }
            };

            container.addEventListener('mousedown', startDragging);
            container.addEventListener('mouseleave', stopDragging);
            container.addEventListener('mouseup', stopDragging);
            container.addEventListener('mousemove', whileDragging);
            container.addEventListener('wheel', handleWheel);

            // Cleanup
            return () => {
                container.removeEventListener('mousedown', startDragging);
                container.removeEventListener('mouseleave', stopDragging);
                container.removeEventListener('mouseup', stopDragging);
                container.removeEventListener('mousemove', whileDragging);
                container.removeEventListener('wheel', handleWheel);
            };
        }
    }, [isMobile]);


    // Update menu item widths
    useLayoutEffect(() => {
        const updateWidthWithRetry = (retries = 3, delay = 100) => {
            setTimeout(() => {
                let allWidthsValid = true;

                menuItemsRef.current.forEach((item, index) => {
                    if (item) {
                        const link = item.querySelector('a');
                        const activeLine = item.querySelector('.menu-li-active');
                        const section = item.getAttribute('data-section');
                        const linkWidth = link.getBoundingClientRect().width || link.offsetWidth;

                        console.log(`Section: ${section}, Link Width: ${linkWidth}px`);

                        if (linkWidth > 0) {
                            console.log(`Updating menu item for section: ${section}`);
                            gsap.set(activeLine, { width: 0 });
                            gsap.to(activeLine, {
                                width: `${linkWidth - 20}px`,
                                duration: 0.4,
                                ease: 'power2.out',
                            });
                        } else {
                            console.log(`Skipping update for section: ${section} due to invalid width.`);
                            allWidthsValid = false;
                        }
                    } else {
                        console.log(`Menu item at index ${index} is missing.`);
                    }
                });

                if (!allWidthsValid && retries > 0) {
                    console.log(`Retrying width calculation... Attempts remaining: ${retries - 1}`);
                    updateWidthWithRetry(retries - 1, delay + 100);
                }
            }, delay);
        };

        updateWidthWithRetry();
    }, []);

    // Scroll to specific section based on ID
    const scrollToSection = useCallback((id) => {
        const section = document.getElementById(id);
        if (section) {
            if (isMobile) {
                // Mobile: Use the native scroll method, no GSAP
                const menuBgHeight = 56; // Fixed height of the menu-bg
                const targetPosition = section.offsetTop - menuBgHeight; // Apply the offset

                horizontalSectionsContainerRef.current.scrollTo({
                    top: targetPosition, // Use the offset value
                    behavior: 'smooth', // Smooth scroll
                });
            } else {
                // Desktop: Keep GSAP for horizontal scrolling
                const container = horizontalSectionsContainerRef.current;
                const menuBgWidth = 65; // Fixed width of the menu-bg
                const targetPosition = section.offsetLeft - container.offsetLeft - menuBgWidth;

                setScrollingManually(true);
                setActiveSection(id);

                gsap.to(container, {
                    scrollLeft: targetPosition,
                    duration: 1,
                    ease: 'power4.out',
                    onComplete: () => {
                        setScrollingManually(false);
                    },
                });
            }
            setMenuOpen(false);
        }
    }, [isMobile]);



    // Update active section on scroll
    useEffect(() => {
        const container = horizontalSectionsContainerRef.current;

        const handleScroll = () => {
            if (scrollingManually) return;

            const scrollPosition = container.scrollLeft + window.innerWidth / 2;

            sectionRefs.current.forEach((section) => {
                const sectionLeft = section.offsetLeft;
                const sectionRight = sectionLeft + section.offsetWidth;

                if (scrollPosition >= sectionLeft && scrollPosition <= sectionRight) {
                    const sectionId = section.id;
                    if (sectionId !== activeSection) {
                        setActiveSection(sectionId);
                    }
                }
            });
        };

        if (!isMobile && container) {
            container.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, [activeSection, scrollingManually, isMobile]);


    // Ensure menu state and active section updates are applied properly
    useEffect(() => {
        if (menuOpen) {
            menuRef.current.style.visibility = 'visible';
            openMenu();
            setTimeout(() => {
                updateMenu();
            }, 100); // Adjust the delay as needed
        } else {
            closeMenu();
        }
    }, [menuOpen, openMenu, closeMenu, updateMenu]);

    // Add ResizeObserver to watch menu item changes
    useEffect(() => {
        const observer = new ResizeObserver(() => {
            updateMenu();
        });

        menuItemsRef.current.forEach((item) => {
            if (item) {
                observer.observe(item);
            }
        });

        return () => {
            observer.disconnect();
        };
    }, [updateMenu]);

    // Handle preloader and content visibility
    useEffect(() => {
        if (isLoaded) {
            gsap.to('.App', { opacity: 1, duration: 0.5 });
        }
    }, [isLoaded]);

    useEffect(() => {
        // Simulate loading content
        setTimeout(() => {
            setLoading(false);
        }, 2000); // timeout duration
    }, []);

    useEffect(() => {
        if (!loading) {
            // Animate the app container to fade in and slide up
            gsap.to(appRef.current, {
                duration: 1, // Duration of the animation
                y: 0, // Final position
                opacity: 1, // Final opacity
                ease: "power4.out", // Easing function
                onComplete: () => {
                    console.log('Content animation complete.');
                }
            });
        }
    }, [loading]);

    useEffect(() => {
        if (!loading) {
            // Ensure GSAP states are cleared
            gsap.killTweensOf(menuRef.current);
            gsap.set(menuRef.current, { clearProps: 'all' });
            console.log('GSAP state cleared after preloader');
        }
    }, [loading]);

    // Function to get CSS variables
    const getCSSVariable = (variable) => {
        return getComputedStyle(document.documentElement).getPropertyValue(variable).trim();
    };

// Dedicated useEffect for menu-close button animation
    useEffect(() => {
        if (menuOpen) {
            // Trigger the CSS transition via GSAP for opening
            gsap.set('.menu-close', { clipPath: 'inset(0 100% 0 0)' });
            gsap.to('.menu-close', { clipPath: 'inset(0 0% 0 0)', delay: 1, duration: 0 }); // Allow CSS to handle the transition
        } else {
            // Trigger the CSS transition for closing
            gsap.to('.menu-close', {
                clipPath: 'inset(0 100% 0 0)',
                duration: 0.1,
                ease: 'power4.in',
                onComplete: () => {
                    setMenuOpen(false); // Delay the actual state change until the animation finishes
                }
            });
        }
    }, [menuOpen]);

    // Handle initialization scroll behavior
    useEffect(() => {
        const horizontalSectionsContainer = horizontalSectionsContainerRef.current;

        if (horizontalSectionsContainer) {
            horizontalSectionsContainer.scrollLeft = 1;
            horizontalSectionsContainer.scrollLeft = 0;

            gsap.to(horizontalSectionsContainer, {
                scrollTo: { x: 1 }, // Scroll 1px to trigger any initialization
                duration: 0.1,
                onComplete: () => {
                    gsap.to(horizontalSectionsContainer, { scrollTo: { x: 0 }, duration: 0.1 });
                }
            });
        }
    }, []);


    useEffect(() => {
        updateMenu();
    }, [activeSection, updateMenu]);

    // Function to handle window resize and update isMobile ref
    useEffect(() => {
        const handleResize = () => {
            isMobile.current = window.innerWidth <= 767;
        };
        window.addEventListener('resize', handleResize);

        // Cleanup on unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMobile]);



    useEffect(() => {
        const homeButton = document.querySelector('.home-button');
        // Add the transition-enabled class after a brief delay to avoid the initial transition
        setTimeout(() => {
            homeButton.classList.add('transition-enabled');
        }, 10);  // Adjust the delay if necessary
    }, []);

    useEffect(() => {
        const homeButtonIcon = document.querySelector('.home-button-icon');
        homeButtonIcon.classList.add('loaded');
    }, []);


    useEffect(() => {
        const container = horizontalSectionsContainerRef.current;
        let isDown = false;
        let startX;
        let scrollLeft;

        // Add the grabbable class by default
        container.classList.add('grabbable');

        const startDragging = (e) => {
            isDown = true;
            startX = e.pageX - container.offsetLeft;
            scrollLeft = container.scrollLeft;

            // Add the no-select class and change cursor to grabbing
            document.body.classList.add('no-select');
        };

        const stopDragging = () => {
            isDown = false;

            // Remove the no-select class and revert cursor
            document.body.classList.remove('no-select');
        };

        const whileDragging = (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - container.offsetLeft;
            const walk = (x - startX) * 3; // Adjust scrolling speed if needed

            // Use GSAP to animate the scroll, adding the desired easing effect
            gsap.to(container, {
                scrollLeft: scrollLeft - walk,
                duration: 1, // Adjust the duration for the easing effect
                ease: "power4.out", // Adjust easing for the gravity-like effect
            });
        };


        const handleWheel = (e) => {
            if (e.deltaY !== 0) {
                e.preventDefault();
                const currentScroll = container.scrollLeft;
                gsap.killTweensOf(container);
                gsap.to(container, {
                    scrollLeft: currentScroll + e.deltaY * 3, // Adjust speed multiplier as needed
                    duration: 1,
                    ease: "power4.out",
                });
            }
        };

        container.addEventListener('mousedown', startDragging);
        container.addEventListener('mouseleave', stopDragging);
        container.addEventListener('mouseup', stopDragging);
        container.addEventListener('mousemove', whileDragging);
        container.addEventListener('wheel', handleWheel);

        return () => {
            container.removeEventListener('mousedown', startDragging);
            container.removeEventListener('mouseleave', stopDragging);
            container.removeEventListener('mouseup', stopDragging);
            container.removeEventListener('mousemove', whileDragging);
            container.removeEventListener('wheel', handleWheel);

            // Remove the no-select class in case the component unmounts during a drag
            document.body.classList.remove('no-select');
        };
    }, []);


    return (
        <>
            {!isLoaded && <Preloader onComplete={handlePreloaderComplete} />}
            {isPortrait && isIpad() && ( // Show the message only for iPad in portrait mode
                <div className="portrait-msg">
                    <div className="message">
                        <h1>Please rotate your device</h1>
                        <p>This site is best viewed in landscape mode.</p>
                    </div>
                </div>
            )}
            <div className={`App ${isLoaded ? 'content-visible' : 'content-hidden'}`}>
                    <div className="menu-button" role="button" aria-expanded={menuOpen} onClick={toggleMenu} tabIndex={0}>
                        <div className="hamburger-icon">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    <div className="menu-container" ref={menuRef}>
                    </div>
                    <div className="menu-mask">
                        <ul className="menu">
                            {['Intro', 'Rediger', 'Davis', 'The Farm', 'Reunion'].map((item, index) => (
                                item !== 'Intro' && (
                                    <li key={item} ref={el => menuItemsRef.current[index + 1] = el} className="menu-item-wrapper" data-section={item.toLowerCase().replace(/\s+/g, '-')}>
                                        <div className={`menu-item ${activeSection === item.toLowerCase().replace(/\s+/g, '-') ? 'active' : ''}`}>
                                            <a href="#" onClick={(e) => { e.preventDefault(); scrollToSection(item.toLowerCase().replace(/\s+/g, '-')); }}>
                                                {item}
                                            </a>
                                            <div className="menu-li-active"></div>
                                        </div>
                                    </li>
                                )
                            ))}
                        </ul>

                    </div>
                    <div className="menu-close" onClick={() => setMenuOpen(false)}>
                        <FontAwesomeIcon icon={faCircleXmark} />
                    </div>
                    <div className="menu-bg"> </div>
                <div className="horizontal-sections" ref={horizontalSectionsContainerRef}>
                    <div ref={el => sectionRefs.current[0] = el} className="horizontal-section home" id="intro">
                        <div className="title-container">
                            <h1 className="svg-heading"><img src={require('./images/davis.svg')} alt="Davis" className="color-heading" /></h1>
                            <p>Explore the beginnings of the Davis Family Reunion, and the families that made up their history.</p></div>
                        <div className="navigate"><p>Drag right or scroll to explore</p>
                            <a href="#" onClick={(e) => {
                                e.preventDefault();
                                scrollToSection('rediger');
                            }}
                               role="button"
                               aria-label="Begin"
                               aria-expanded={menuOpen ? "true" : "false"}
                               aria-controls="intro"
                            ><FontAwesomeIcon icon={faCircleArrowRight} /></a>
                        </div>
                    </div>
                    <div className="horizontal-section foreword">
                        <section>
                            <div className="foreword-container">
                                <h1>Foreword</h1>

                                <p>This website is dedicated to my family. To those from the past that are no longer with us, those of the present, and those yet to come.</p>
                            </div>
                        </section></div>
                    <div ref={el => sectionRefs.current[1] = el} className="horizontal-section rediger" id="rediger">
                        <div className="rediger-main-container">
                            <section className="dr-intro">
                                <div className="chapter-header chap1">
                                    <div className="chap-left">
                                        <div className="chap-name">Rediger</div>
                                    </div>
                                    <div className="chap-right">
                                        <div className="grad-overlay"> </div>
                                        <figure>
                                            <figcaption>Rediger family gathering<span>Circa 1917</span></figcaption>
                                        </figure>
                                    </div>
                                </div>
                                <figure className="dr-grave-container">                                        <figcaption>David Rediger Grave<span>Washburn, IL</span></figcaption>
                                </figure>
                                <div className="dr-intro-beginning">
                                    <h1><span>David</span> Rediger</h1>
                                    <div className="dr-intro-p"><p>Born in Illinois, 1841, David was well known in the community. He was a wealthy farmer in an area known as Richland, near Washburn, IL. His family and hired hands helped him maintain the farm.</p><p>Though I've not been able to find a photo of David, I imagine he was hard working, outspoken and opinionated. He was a staunch democrat in a time when there was deep divide after the Civil War. Abraham Lincoln was a republican - I wonder what he thought of Honest Abe?</p></div>
                                </div>
                                <div className="dr-oct-container">
                                    <div className="dr-oct-wrapper">
                                        <div className="oct-clipping"> </div>
                                        <div className="dr-oct"><h2>Oct 1879</h2>
                                            <div className="paper">Marshall County Democrat</div>
                                            <p>The “mischievous chaps” that caused the apple spill - I wonder if they did this as a joke, or because they didn’t like him (or both). Either way, it seems he wasn’t amused.</p>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="dr-lightning">
                                <h1><span className="fw">Struck by</span> <span className="sw">Lightning</span></h1>
                                <div className="dr-lightning-content">
                                    <div className="lightning-intro-container">
                                        <p className="lightning-intro">In 1883, David and his firstborn son Alex were killed instantly by a bolt of lighting while they were planting corn. David was 42, Alex was 17. In his obituary, it stated his funeral was the largest the county had ever seen. There were no less than 183 vehicles present, with <strong>“most of them being democrat”</strong>. And we thought our generation was divided...</p>
                                    </div>
                                    <div className="mary-rediger-wrapper">
                                        <div className="mary-rediger-container">
                                            <div className="mary-rediger-photo"> </div>
                                            <div className="mary-rediger-content">
                                                <h2>David’s wife, Mary Jane</h2>
                                                <p>Mary Jane (Duchesne) Rediger lived a long life, from 1842-1931. A Victorian woman through and through, she always wore black dresses to represent the mourning of her husband, all the way until the 1920’s.  </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </section>
                            <section className="dr-minnie">
                                <h1><span>Minnie</span> Rediger</h1>
                                <div className="minnie-content">
                                    <div className="minnie-young"><img src={require('./images/minnie-young.webp')} alt="Minnie Rediger - 1880's" /></div>
                                    <div className="minnie-reaper-container">
                                        <p>"Miss Minnie Rediger drives the reaper this harvest. That is the kind of girl to have, don’t you forget it."</p>
                                        <div className="cite">Marshall County Democrat<br/>Richland Social Column<br/>August 1882</div>
                                    </div>
                                    <p className="minnie-p">Like most families from the Victorian Era, David had several kids. His oldest daughter was my 2nd great grandmother, Mary “Minnie” Rediger. I imagine she had a nice upbringing due to David’s success, but not so comfortable that she was a debutant.  David only had 2 sons, and his last son, Walter, was only 1 year old when he died. So, the girls had to pitch in and help with the farm work. I imagine she was a tough old broad, never one to shy away from the hard work that was required for 19th century farming.</p>
                                </div>
                            </section>
                        </div>
                    </div>
                    <div ref={el => sectionRefs.current[2] = el} className="horizontal-section davis" id="davis">
                        <div className="davis-main-container">
                            <section className="jd-intro">
                                <div className="chapter-header chap2">
                                    <div className="chap-left">
                                        <div className="chap-name">Davis</div>
                                    </div>
                                    <div className="chap-right">
                                        <div className="grad-overlay"> </div>
                                        <figure>
                                            <figcaption>John Henry Davis family <span>Circa 1914</span></figcaption>
                                        </figure>
                                    </div>
                                </div>

                                <figure className="jd-grave-container">
                                    <figcaption>John S. Davis Grave<span>Roanoke, IL</span>
                                        <p>The white behind his grave is his original headstone. Because he was a Civil War veteran, the military replaced it after it had deteriorated.</p></figcaption>
                                </figure>
                                <div className="jd-intro-beginning">
                                    <h1><span>John S.</span> Davis</h1>
                                    <div className="jd-content">
                                        <div className="jd-intro-p"><p>John S. Davis, my 3rd great grandfather, was born in Indiana in 1832. When he was 20 years old, he married my 3rd great grandmother, Sarah McFarland. During the Civil War, John answered the call to serve, enlisting in the Union Army in 1863.</p><p>John and Sarah had 9 children, including my 2nd great grandfather, Henry Davis. Sadly, the injuries John sustained during the war took a toll on his health, and he passed away at the young age of 39 in 1871. His legacy, however, lived on through his children and the generations that followed.
                                        </p>
                                        </div>
                                        <div className="sm-container">
                                            <div className="sm-wrapper">
                                                <div className="sm-text"><h2>John’s wife, Sarah</h2>
                                                    <p>Sarah is pictured on the far left, with her brother and sister.  She would go on to marry another widower and live a long life, until she was 79 years of age. She is buried in the same cemetary as several of her family members, but in an an older cemetary where the earth has swollowed up many of the headstones. Sadly, nobody has been able to find hers.</p>
                                                </div>
                                                <div className="sm-photo"> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="jd-henry">
                                <h1><span>John Henry</span> Davis</h1>
                                <div className="jd-henry-content">
                                    <div className="henry-young">
                                        <img src={require('./images/henry-young1.webp')} alt="Henry Davis - 1880's" />
                                    </div>
                                    <div className="henry-container">
                                        <p>Henry Davis, otherwise known as John Henry, was born on August 30, 1861, in Franklin County, Indiana.</p>
                                        <div className="henry-p"><p>During his childhood, his family relocated to Woodford County,
                                            Illinois, where the agricultural boom provided ample opportunities for farming. At just
                                            10 years old, John Henry faced the loss of his father. Despite this hardship, he grew up
                                            in a region known for its tight-knit communities and hard-working families, where
                                            neighbors often supported one another through difficult times. Central Illinois in the
                                            late 19th century was marked by vast expanses of farmland, bustling small towns, and the
                                            steady growth of railroads, which connected rural communities to larger markets.</p>

                                            <p>As John Henry entered adulthood, he was well-prepared to embrace the agricultural
                                                lifestyle that dominated the region. The fertile land, coupled with advancements in
                                                farming techniques and equipment, offered him the chance to build a life and family
                                                grounded in the rhythms of the land. He worked tirelessly, adopting new methods of
                                                cultivation and investing in modern equipment to make the most of the bountiful
                                                harvests the area was known for. It was a time of optimism and growth, with new
                                                opportunities emerging for those willing to work the soil.</p></div>
                                    </div>

                                </div>
                            </section>
                        </div>
                    </div>
                    <div ref={el => sectionRefs.current[3] = el} className="horizontal-section farm" id="the-farm">
                        <div className="farm-main-container">
                            <section className="farm-intro">
                                <div className="chapter-header chap3">
                                    <div className="chap-left">
                                        <div className="chap-name">The Farm</div>
                                    </div>
                                    <div className="chap-right">
                                        <div className="grad-overlay"> </div>
                                        <figure>
                                            <figcaption>Top: Minnie Davis & Louella Davis<br />Bottom: John Henry Davis and brother Bob Davis<span> Circa 1920</span></figcaption>
                                        </figure>
                                    </div>
                                </div>
                                <div className="farm-intro-beginning">
                                    <div className="farm-intro-p"><p>John Henry Davis and Minnie Rediger married in 1885, embarking on a journey that would see them traverse the heartland of America. After spending several years moving between central Illinois and Britt, Iowa, the couple eventually found their permanent home in Illinois during the 1920s.<br /><br />It was during this period of stability that John Henry purchased land in Peoria, in an area that would later be known as Mt. Hawley and Pioneer Park. This land would become the Davis family farm, a cornerstone of their legacy.
                                    </p>
                                    </div>
                                </div>
                            </section>
                            <section className="farm-photo-hats-container">
                                <figure className="farm-photo-hats">
                                    <div className="photo-hold"> </div>
                                    <figcaption>
                                        <div className="caption-title">Davis & Rediger family gathering <div className="circa">Circa 1917<span>(Colorized)</span></div></div>
                                        <div className="caption-names">
                                            <div><span>Top row, left to right:</span> Walter Rediger, Walter Scott
                                                Rediger, Ben Adami, Unknown, Abner Crank, Flossie Davis Adami, Frank
                                                Davis, Unknown, Minnie Rediger, Unknown, John Henry Davis, Mary Jane
                                                Rediger, Mary Rediger Davis
                                            </div>
                                            <div><span>Middle row, left to right:</span>The two adult females are Ella
                                                Rediger Storm and Henrietta Rediger Crank. The names of the children are
                                                unknown.
                                            </div>
                                        </div>
                                    </figcaption>
                                </figure>
                            </section>
                            <section className="farm-grey">
                                <div className="prosperity">
                                    <h1>A time of <span>prosperity</span></h1>
                                    <p>The early 20th century was a time of prosperity and rapid change across the United States. The economy was booming, and John Henry and Minnie seized the opportunity to build a successful farming operation. Their farm, equipped with modern machinery, thrived during these years of economic growth.</p>
                                </div>
                                <div className="prosperity-photos">
                                    <div className="prosperity-photo henry-photo">
                                        <figcaption>1922</figcaption>
                                    </div>
                                    <div className="prosperity-photo minnie-photo"> </div>
                                </div>
                            </section>
                            <section className="farm-black">
                                <div className="swindle">
                                    <h1>The great <span>swindle</span></h1>
                                    <p>The same era that brought prosperity also harbored deep corruption and exploitation. The lack of regulation allowed unscrupulous individuals to take advantage of others, and John Henry found himself swindled out of much of his land by corrupt attorneys. This loss was devastating, but the worst was yet to come.</p>
                                </div>
                                <div className="swindle-img">
                                    <img src={require('./images/mary-davis-dog.webp')} alt="Mary Davis" />
                                </div>
                                <div className="swindle-last">
                                    <p>The Great Depression that followed in the 1930s brought widespread suffering across the nation, and the Davis family was no exception. As Henry’s health declined, they were forced to sell the remaining land that hadn't been lost to the swindle.<br /><br />Henry’s death left Minnie and the family in dire straits. Once a woman of considerable means, Minnie found herself with little more than memories of better times. She moved into a small house in downtown Peoria and, at the age of 72, began taking in boarders to make ends meet. One family of boarders was a young couple with a toddler, a far cry from the life Minnie had once envisioned for her golden years.</p>
                                </div>
                            </section>
                            <section className="vs-wrapper">
                                <div className="vs-container">
                                    <div className="vs-text"><h2><span>Davis</span> <span className="midspan">vs.</span> <span>Rediger</span></h2>
                                        <p>After Henry’s death and the loss of nearly all the family's land and savings due to the swindle, Minnie found herself with very little left. As the Great Depression unfolded, making everything even harder, she sought financial relief by attempting to claim what she believed was her share of her father’s estate. Her youngest brother, Walter Rediger, had become the keeper of the estate, possibly the executor.</p>

                                        <p>In the legal pursuit, Minnie and her sister Ella took Walter and their two other sisters, Ada Belle and Clara, to court. The case involved a partition claim that could have resulted in the sale of the estate. However, the case was ultimately dismissed before reaching court. Whether it was settled privately among the siblings or if Minnie simply gave up remains unclear. She passed away in 1943, having endured the harsh realities of a changing world.</p>

                                        <p>In retrospect, it's fortunate that the estate wasn’t sold. Given the economic collapse during the Depression, any property sale would have likely fetched only a fraction of its value, leaving the family with almost nothing. The Walter Rediger family descendents continue to own the farm to this day—a testament to their resilience through incredibly hard times.</p>
                                    </div>
                                </div>
                            </section>
                            <section className="davis-final-wrapper">
                                <div className="davis-final-content">
                                    <div className="davis-final-header-col">
                                        <h2>Life Goes On</h2>
                                    </div>
                                    <div className="davis-final-column">
                                        <p className="davis-col-first">In an era when large families were both common and essential, children were viewed as a source of both wealth and labor. They were vital to the survival of the family farm, helping with chores, fieldwork, and managing the household. Despite the hardships Henry and Minnie faced, I believe they would take comfort in knowing that their legacy lives on through their children and grandchildren.</p>
                                        <p>John Henry and Minnie raised 11 children, 10 of whom survived into adulthood. Among them was my great-grandfather, Lester Davis, who married my great-grandmother, Lola. Together, they raised 9 children of their own.</p>
                                    </div>

                                    <div className="davis-final-column">
                                        <figure>
                                            <img src={require('./images/lester-lola.webp')} alt="Lester & Lola Davis on their wedding day" />
                                            <figcaption>Lola & Lester Davis on their wedding day, 1914</figcaption>
                                        </figure>
                                    </div>

                                    <div className="davis-final-column"><p>Even through the challenges, the Davis family understood the importance of staying together. As Henry's health declined, his daughter Alice Harper, along with her cousins Arvilla Glaub and Undena Krueger, took the initiative to create what would become a cherished family tradition—the Davis  Reunion.</p><p>Each year, the Davis Reunion brought laughter, memories, and the opportunity to strengthen ties across distant branches of the family tree. The tradition lived on for many years, reminding all who attend of the deep roots and strong bonds that define the Davis family.</p></div>

                                </div>
                            </section>
                        </div>
                    </div>
                    <div ref={el => sectionRefs.current[4] = el} className="horizontal-section reunion" id="reunion">
                        <div className="reunion-main-container">
                            <section className="reunion-intro">
                                <div className="chapter-header chap4">
                                    <div className="chap-left">
                                        <div className="chap-name">Reunion</div>
                                    </div>
                                    <div className="chap-right">
                                        <div className="grad-overlay"> </div>
                                        <figure>
                                            <figcaption>Davis ladies at a family gathering<br /><span>Circa 1933</span></figcaption>
                                        </figure>
                                    </div>
                                </div>
                                <div className="reunion-intro-beginning">
                                    <div className="reunion-intro-p"><p>The first Davis Reunion was held on September 1, 1929. It was organized by Alice (Davis) Harper, daughter of John Henry, along with Arvilla (Davis) Glaub, John Henry's niece, and Undeena (Glaub) Krueger, daughter of Arvilla. The reunion was held in honor of John Henry Davis, who was the last surviving member of John and Sarah Davis's family at that time. The date of September 1st was chosen to align with John Henry's birthday on August 30th.</p><p>The first reunion was a great success. Early September of 1929 was a time of prosperity—the economy was booming, and the stock market was at an all-time high. Little did they know, the market crash and the Great Depression were only weeks away. But on that day, spirits were high. Held at Grant Park in Washburn, IL, 125 family members attended, spending the day visiting, playing games, and enjoying each other's company. It was the largest Davis family gathering in years, and the family decided, "It will become an established annual reunion hereafter."</p><p>In 1932, the reunion was in its fourth year. Although it was planned for Washburn, John Henry's declining health made it difficult for him to travel, so the reunion was held at the family farm in Peoria. There were races, contests, games, and even a boxing match! Sadly, John Henry passed away just 28 days after the event.</p></div>
                                </div>
                                <div className="reunion-photo-insert"><figure><img src={require('./images/dance.webp')} alt="Mary Anne and sister" /><figcaption><div className="reunion-photo-title">Dancing Cousins, Circa 1940s</div><div className="frank-txt">My grandmother, Mary Anne and her cousin Mabel.</div></figcaption></figure></div>
                            </section>
                            <section className="milestones">
                                <div className="milestones-intro">
                                    <h1>Memories <span>& Milestones</span></h1>
                                    <div className="milestones-intro-content">
                                        <p>During the first reunion, the family elected their first officers: a president, vice president, secretary and several chairpersons. The secretary kept a detailed book of minutes, recording the year's reunion events, including marriages, births, and deaths. Each year, a moment was set aside to honor those who had passed.</p><p>The family discussed ways to improve the reunion, where to hold it next, and other important matters. Votes were taken on decisions, allowing the reunion to evolve over time. More officers and committees were added, and new traditions were established, but the core format of the meetings remained the same.</p>
                                    </div>
                                </div>
                                <div className="milestones-wrapper">
                                    <div className="stone">
                                        <h2>1929</h2>
                                        <p>The first reunion was held at Grant Park in Washburn, IL, with 125 family members present. The first officers were elected, and it was decided to make the reunion an annual event.</p>
                                    </div>
                                    <div className="stone">
                                        <h2>1931</h2>
                                        <p>An entertainment committee and table committee were added to the elected officers.</p>
                                    </div>
                                    <div className="stone">
                                        <h2>1935</h2>
                                        <p>It was decided that each family should bring chicken, a tradition that remained the entire span of the reunion. To this day, I still remember the buckets of chicken.</p>
                                    </div>
                                    <div className="stone">
                                        <h2>1936</h2>
                                        <p>A proposal to rename the event the "Davis-McFarland Reunion" was voted down. The McFarland name was John Henry's mother's maiden name. I'm not sure why it was voted down, but that would have brought an interesting dynamic.</p>
                                    </div>
                                    <div className="stone">
                                        <h2>1937</h2>
                                        <p>To keep everyone informed and to keep up with addresses, the secretary began sending reminder cards to each family.</p>
                                    </div>
                                    <div className="stone">
                                        <h2>1939</h2>
                                        <p>Several families traveled from Iowa, and from this point, records were kept of who traveled the farthest.</p>
                                    </div>
                                    <div className="stone">
                                        <h2>1942</h2>
                                        <p>For the first time, gifts were given to the oldest man, oldest woman, and youngest baby. Due to gas rationing, it was decided to postpone the reunion until World War II ended.</p>
                                    </div>
                                    <div className="stone">
                                        <h2>1946</h2>
                                        <p>The reunion resumed after the war, now held in the iconic log cabin at the American Legion in Washburn, IL, where it would remain for years. WWII veterans were honored, and the beginning of the baby boomer generation was noted with the phrase, "the stork was very busy."</p>
                                    </div>
                                    <div className="stone">
                                        <h2>1947</h2>
                                        <p>My mother was the youngest member at the reunion. As the youngest baby, I wonder what her prize was?</p>
                                    </div>
                                    <div className="stone">
                                        <h2>1948</h2>
                                        <p>Beer was introduced, and a White Elephant Sale was added to help cover reunion expenses. Walter Scott Davis won a pair of skunks for making the ugliest face! It’s unclear if the skunks were real, but they were auctioned for 75 cents.</p>
                                    </div>

                                    <div className="stone">
                                        <h2>1952</h2>
                                        <p>The reunion was held in Mason City, IA, to accommodate relatives from Iowa. It was also held there in 1956 and 1960.</p>
                                    </div>
                                    <div className="stone">
                                        <h2>1956</h2>
                                        <p>A cemetery committee was formed to care for the graves of John Henry, Minnie, and other relatives.</p>
                                    </div>
                                    <div className="stone">
                                        <h2>1961</h2>
                                        <p>The president "kicked off the reunion with a BANG" lighting a firecracker! I hope it was outside.</p>
                                    </div>
                                    <div className="stone">
                                        <h2>1972</h2>
                                        <p>The tradition of the "tallest tale" began. The best storyteller won a prize.</p>
                                    </div>
                                    <div className="stone">
                                        <h2>1978</h2>
                                        <p>Reunion books containing all the minutes from past reunions were auctioned off. Lite beer and diet soda made their first appearance, a nod to the growing health trends.</p>
                                    </div>
                                    <div className="stone">
                                        <h2>1979</h2>
                                        <p>The 50th reunion was held at the Washburn cabin. Frank Davis, having missed only a few reunions, was honored, and a special plaque was created for the oldest family member, passed down each year.</p>
                                    </div>
                                    <div className="stone">
                                        <h2>1981</h2>
                                        <p>The reunion moved to the American Legion in Metamora, IL. The cost of the cabin had increased, and the Metamora location had better facilities and air conditioning.</p>
                                    </div>
                                    <div className="stone">
                                        <h2>1986</h2>
                                        <p>Reunion attendance began to decline as some original members passed away. Flyers were sent to boost attendance a few years later.</p>
                                    </div>
                                    <div className="stone">
                                        <h2>1990</h2>
                                        <p>The reunion returned to the Washburn cabin for nostalgia’s sake.</p>
                                    </div>
                                    <div className="stone">
                                        <h2>1999</h2>
                                        <p>Donations to St. Jude began on behalf of the Davis Reunion. This was one of many donations throughout the years.</p>
                                    </div>
                                    <div className="stone">
                                        <h2>2000</h2>
                                        <p>Attendance began to rebound, with 115 family members, the largest in years.</p>
                                    </div>
                                    <div className="stone">
                                        <h2>2003</h2>
                                        <p>Newsletters became a new method of communication, with family history and reunion dates to keep family members informed.</p>
                                    </div>
                                    <div className="stone">
                                        <h2>2004</h2>
                                        <p>The 75th reunion drew over 130 people. This was the last reunion I attended.</p>
                                    </div>
                                    <div className="stone">
                                        <h2>2005</h2>
                                        <p>Hurricane Katrina caused gas prices to spike, leading to lower attendance.</p>
                                    </div>
                                    <div className="stone">
                                        <h2>2009</h2>
                                        <p>There was no longer a lot of people from the Washburn area that attended the reunion, so it was moved to the VFW in Chillicothe, IL.</p>
                                    </div>
                                    <div className="stone">
                                        <h2>2011</h2>
                                        <p>Themed reunions began: a Hawaiian luau in 2011 and a disco theme in 2012.</p>
                                    </div>
                                    <div className="stone">
                                        <h2>2016</h2>
                                        <p>For the first time in history, the date was moved away from Labor Day weekend in hopes of drawing younger attendees (it did not).</p>
                                    </div>
                                    <div className="stone">
                                        <h2>2018</h2>
                                        <p>A motion to cancel the reunion due to low attendance was proposed but voted down.</p>
                                    </div>
                                    <div className="stone">
                                        <h2>2020</h2>
                                        <p>The reunion was canceled due to the COVID-19 pandemic.</p>
                                    </div>
                                    <div className="stone">
                                        <h2>2023</h2>
                                        <p>After 95 years, it was voted on and decided that this would be the final reunion.</p>
                                    </div>

                                </div>

                            </section>
                            <section>
                                <div className="reunion-photo-insert frank-photo"><figure><img src={require('./images/frank.webp')} alt="Frank and Mildred at the 50th Reunion" /><figcaption><div className="reunion-photo-title">Frank Davis and his wife, Mildred</div><div className="frank-txt">Frank, who was John Henry’s son, only missed a few reunions in his lifetime. This was at the 50th reunion in 1979.</div></figcaption></figure></div>
                            </section>
                            <section>
                                <div className="to-me">
                                    <div className="to-me-container">
                                        <h1>Personal <span>Reflections</span></h1>
                                        <div className="to-me-p">
                                            <p>When I was young, the reunion was something I always looked forward to. It was held just before school started, and I was eager to show off my favorite new fall outfits that had just been bought for the upcoming school year. Of course, my mom knew I wouldn’t last long in those clothes, so she always packed something more appropriate for the hot August weather.</p>

                                            <p>While the adults held their business meetings, the kids would get to play games to win prizes and candy. As we got older, my cousins and I had a blast exploring the parks where the reunions were held, especially the one in Metamora. We'd go to the park and mingle with the local kids. Even though people would talk about how much they missed the Washburn log cabin, some of my best memories were at that park in Metamora.</p>

                                            <p>And the food—it was always incredible! Every family brought their best dishes, so there was no shortage of homemade favorites. Fried chicken, delicious sides, and desserts—there was something special about the spread that made the reunion feel like a true celebration. After the business meeting, everyone gathered around the tables to share a meal together, and you could always count on the food being some of the best you’d ever tasted.</p>

                                        </div>


                                        <div className="seventyfive-photo"><figure><img src={require('./images/reunion-75.webp')} alt="75th reunion" /><figcaption><div className="title">75th Davis Reunion, 2006</div><div className="txt">I am in the center with the white shirt. My grandma, Mary Anne is to the right in the purple shirt.</div></figcaption></figure></div>

                                        <div className="to-me-p-second">

                                            <p>I'd always get drinks of beer from my grandma and then pretend to be drunk. Though I wasn’t, for some reason I found it fun to pretend I was. I can still picture the men gathered around the keg, and the big card games that would follow after everyone had eaten.</p>

                                            <p>But the best times were when the reunion was over, and my grandparents hosted family from out of town at their house on the river. This meant that I got to spend more time with cousins who had traveled to attend, and local cousins would usually join us as well. Those late-night get-togethers were some of the best childhood memories I have.</p>

                                            <p>As I got older, I attended the reunion less. After I went off to college and then moved away, I didn’t return to the reunion until 1999, which was the first one I had been to in years. By then, I was finally old enough to drink with the adults. It was great to see everyone again, but it was also bittersweet. My great aunts and uncles were much older than I remembered, and it was hard seeing how time had changed them.</p>

                                            <p>After I moved to Tennessee, my mom insisted that I return for the 75th anniversary in 2004, and I’m glad I did. It was another big reunion, with over 130 people attending. It was wonderful to be part of such a large gathering, though I regret not attending more reunions after that.</p>

                                            <p>Despite my regrets, I’m grateful for the memories I have and for being part of such a big, wonderful family.</p>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section>
                                <div className="to-me">
                                    <div className="to-me-container the-end"><h1>End of <span>an Era</span></h1>
                                        <div className="to-me-p-second"><p>The dream had always been to keep the reunion
                                            going until the 100th anniversary, but sadly, we didn’t make it that far.
                                            Attendance dwindled over the years, and I can’t help but feel a sense of
                                            responsibility. My generation let it slip away. While some still carried the
                                            torch, most of us lost the enthusiasm our grandparents and parents had for
                                            this tradition.</p><p>Looking back, it’s easy to see how fortunate we were.
                                            All most of us had to do was show up and bring a dish, and yet, many of us
                                            didn’t. I live several states away, but that’s no excuse—many traveled from
                                            further distances.</p><p>At the final reunion in 2023, the remaining money
                                            in the treasury was divided up and donated to various charities, as decided
                                            by those who attended. It was a fitting way to close this chapter of our
                                            family’s history, with a legacy of giving and helping others.</p><p>I’m
                                            grateful that my mom’s generation now has their "cousin’s lunch," and
                                            perhaps someday, my generation will start something of our own.</p></div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>

                <a href="#" onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('intro');
                }}
                   className="home-button"
                   role="button"
                   aria-label="Scroll to Introduction"
                   aria-expanded={menuOpen ? "true" : "false"}
                   aria-controls="intro"
                >
                    <i className="fa-kit fa-davis-menu-icon home-button-logo" aria-hidden="true"></i>
                </a>


                <button onClick={(e) => { e.preventDefault(); scrollToSection('intro'); }} className="home-button-icon">
                    <FontAwesomeIcon icon={faHome} />
                </button>
                <div className="vertical-line"></div>
            </div>
        </>
    );
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
        window.location.reload();
    });
}

export default App;