import React, { useEffect, useState, useRef } from 'react';
import { gsap } from 'gsap';
import './Preloader.css';

const Preloader = ({ onComplete }) => {
    const [counter, setCounter] = useState(0);
    const preloaderRef = useRef(null);

    useEffect(() => {
        if (counter < 100) {
            const interval = setInterval(() => {
                setCounter(prevCounter => prevCounter + 1);
            }, 30); // Adjust the interval time to control the speed
            return () => clearInterval(interval);
        } else {
            // Ensure fonts are loaded before completing
            const checkFontsLoaded = () => {
                // Check if Adobe fonts are ready
                if (document.fonts && document.fonts.status === 'loaded') {
                    gsap.to(preloaderRef.current, {
                        opacity: 0,
                        duration: 1, // Adjust as needed for the fade-out duration
                        ease: "power2.out",
                        onComplete: onComplete, // Notify parent component preloader is done
                    });
                } else {
                    // If fonts aren't ready, check again after a short delay
                    setTimeout(checkFontsLoaded, 100);
                }
            };
            checkFontsLoaded();
        }
    }, [counter, onComplete]);

    return (
        <div className="preloader" ref={preloaderRef}>
            <div className="number"><span>Loading</span><br />{counter}%</div>
        </div>
    );
};

export default Preloader;
